.App {
  text-align: center;
}

/* Trading View Screen */
.viewScreen {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.contain-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item-1x1 {
  height: 98vh;
  width: 98%;
  margin: 1%;
}

.item-2x2 {
  height: 48vh;
  width: 47%;
  margin: 1%;
}

.item-3x3 {
  height: 31vh;
  width: 31%;
  margin: 1%;
}

.title {
  padding: 10px;
  font-size: 2em;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2em;
}

@media screen and (max-width: 450px) {
  .title {
    font-size: 1.2em;
    color: white;
  }
}
